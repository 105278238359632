import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import Button from '../../../../components/button/button';
import { CompanyContext } from '../../../../context/company.context';
import { LangContext } from '../../../../context/lang.context';

export const InitialPage = ({ callback }: { callback: () => any }) => {
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          LIVENESS {
            TITLE_9
            TITLE_10
            TITLE_11
            SUBTITLE_2
            REMINDER_1
            CAMERA {
              STEP_1 {
                BUTTON
              }
            }
          }
        }
      }
    }
  `);

  const handleClick = (e: Event) => {
    e.preventDefault();
    callback();
  };

  const { getPublicURL } = useContext(CompanyContext);
  const { t } = useContext(LangContext);
  return (
    <section className="content">
      <h1 className="text-center text-3xl text-primary-900 mb-5 font-thin leading-tight tracking-tight">
        {t(data).LIVENESS.TITLE_9}
        <p className="mt-2 text-gray-600 text-center pb-3 text-base font-normal leading-6">
          {t(data).LIVENESS.TITLE_10}
        </p>
      </h1>
      <img className="w-5/6 m-auto" src={getPublicURL(`/theme/assets/images/liveness/liveness.svg`)} alt="Success" />
      <div className="text-gray-600 text-center rounded-md text-sm mt-6 mb-5 p-3 bg-primary-o-05 flex">
        <img src={getPublicURL(`/theme/assets/images/liveness/blue-info.svg`)} className="mt-2 mb-3 mx-3" alt="Error" />
        <p className="text-left">{t(data).LIVENESS.TITLE_11}</p>
      </div>
      <Button color={'primary'} className="py-3 mt-10 mx-auto flex justify-center" onClick={(e: any) => handleClick(e)}>
        {t(data).LIVENESS.CAMERA.STEP_1.BUTTON}
      </Button>
    </section>
  );
};
