import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import Button from '../../../../components/button/button';
import { LangContext } from '../../../../context/lang.context';
import { PResumeStyled } from '../../../../styles/pages/failed';
import { returnWhatsapp } from '../../../../utils/browser';
import { SopErrorDefinitions } from '../models';
interface Props {
  info: SopErrorDefinitions;
  callback: () => void;
}

export const SopErrorPage = ({ callback, info }: Props) => {
  const { title_1, title_2, description_1, description_2, imageSrc } = info;
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETRY
          RETURN_WHATSAPP
          LIVENESS_VU {
            SOP {
              MSG {
                DEFAULT_TITLE_3
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section className="content">
      <h1 className="text-center text-4xl mb-5 font-thin tracking-tighter leading-10 mt-5 text-red">
        <b className="font-bold">{title_1}</b> {title_2 || t(data).LIVENESS_VU.SOP.MSG.DEFAULT_TITLE_3}
      </h1>
      <PResumeStyled className="mb-6">{description_1}</PResumeStyled>
      <img className="w-4/6 m-auto" src={imageSrc} alt="Error" />
      <PResumeStyled className="mb-6">{description_2}</PResumeStyled>
      <Button className="mx-auto flex justify-center" color={'primary'} type="button" onClick={() => callback()}>
        {t(data).RETRY}
      </Button>
      <Button
        type="button"
        className="mb-4"
        color={'secondary'}
        onClick={() => {
          returnWhatsapp();
        }}
      >
        {t(data).RETURN_WHATSAPP}
      </Button>
    </section>
  );
};
