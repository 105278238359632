import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import Button from '../../../../components/button/button';
import { LangContext } from '../../../../context/lang.context';
import { CompanyContext } from '../../../../context/company.context';
import { returnWhatsapp } from '../../../../utils/browser';
import { ErrorDefinitions } from '../models';
interface Props {
  info: ErrorDefinitions;
}

export const ErrorPage = ({ info }: Props) => {
  const { description_1, showAlert } = info;
  const { getPublicURL } = useContext(CompanyContext);
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETRY
          RETURN_WHATSAPP
          LIVENESS_VU {
            SOP {
              MSG {
                DEFAULT_TITLE_3
                FG_ALERT_MSG
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section className="content">
      <h1 className="text-center text-4xl my-5 font-thin tracking-tighter leading-10 text-red">
        {t(data).LIVENESS_VU.SOP.MSG.DEFAULT_TITLE_3}
      </h1>
      <p className="text-center px-3 my-5 text-gray-600">{description_1}</p>
      <img
        className="m-auto"
        src={getPublicURL(`/theme/assets/images/error/generic-error.svg`)}
        alt="Livenes Error"
      />
      {showAlert && (
        <div className=" text-gray-800 text-center leading-tight rounded-md text-sm mt-6 mb-5 p-3 bg-red-o-01 flex items-center">
          <img
            src={getPublicURL(`/theme/assets/images/liveness-vu/exclamation-triangle-red.svg`)}
            className="mt-2 mb-3 mx-3"
            alt="Triangle exclamation"
          />
          <p className="text-left leading-tight">{t(data).LIVENESS_VU.SOP.MSG.FG_ALERT_MSG}</p>
        </div>
      )}
      <Button
        type="button"
        className="mb-4"
        color={'primary'}
        onClick={() => {
          returnWhatsapp();
        }}
      >
        {t(data).RETURN_WHATSAPP}
      </Button>
    </section>
  );
};
