export type Currency = {
  id?: string;
  symbol: string;
  description?: string;
  append: boolean;
};
export const getCurrencyFormat = (amount: string, country?: string) => {
  const separators: any = {
    en: [',', '.'],
    es: ['.', ','],
  };

  var floatAmount = Number.parseFloat(amount)
  const applySeparators = (thousandsSeparator: string, decimalSeparator: string) => {

    if (floatAmount % 1 > 0) {
      return floatAmount
          .toFixed(2)
          .toString()
          .replace('.', decimalSeparator)
          .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
    } else {
      return floatAmount
          .toFixed(0)
          .toString()
          .replace('.', decimalSeparator)
          .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
    }
  }

  return applySeparators(...separators[country ?? 'es']);
};

export const cuitFormatter = (cuit: string) => cuit.replace(/(^[\d]{2})(\d.*)(\d)+(?!.*\d)/, '$1-$2-$3');
export const currencyFormatter = (currency: Currency, amount: string): string => {
  amount = getCurrencyFormat(amount, window.localStorage.getItem('pagochat-lang-key') || 'es');
  return currency.append ? amount + currency.symbol : currency.symbol + amount;
};
