import styled from '@emotion/styled';
import tw from 'twin.macro';
import { GatsbyImage } from 'gatsby-plugin-image';
import { TitleStyledProps, PStyledProps } from './models';

const TitleStyled = styled.h1`
  ${tw`text-center text-4xl mb-5 font-thin`}

  ${({ compact }: TitleStyledProps) => (compact ? tw`tracking-tighter leading-10 ` : null)}
  ${({ isPrimary }: TitleStyledProps) => (isPrimary ? tw`text-primary-900` : tw`text-red`)}
  ${({ top }: TitleStyledProps) =>
    (({
      '5': tw`mt-5`,
      default: null,
    } as any)[top ?? 'default'])}

  b {
    ${tw`font-bold`}
  }
`;

const PStyled = styled.p`
  ${tw`font-thin my-5`}
  ${({ red }: PStyledProps) => (red ? tw`text-red` : tw`text-primary-900`)}

  b {
    ${tw`font-bold`}
  }
`;

const PResumeStyled = styled.p`
  ${tw`text-center font-thin px-1 mt-5 text-sm text-gray-600`}

  b {
    ${tw`font-bold`}
  }
`;

const ImgStyled = styled(GatsbyImage)`
  ${tw`w-5/6`}
`;

export { TitleStyled, ImgStyled, PStyled, PResumeStyled };
