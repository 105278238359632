import Button from '../../../../components/button/button';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import { LangContext } from '../../../../context/lang.context';
import { cuitFormatter } from '../../../../utils/formatters';
import { CompanyContext } from '../../../../context/company.context';
import { IResponse } from '../models';

export const SuccessPage = ({ texts, callback }: { texts: IResponse; callback: () => any }) => {
  const { feedback, action } = texts;
  const { t } = useContext(LangContext);
  const { getPublicURL } = useContext(CompanyContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETURN_WHATSAPP
          LIVENESS {
            TITLE_6
            TITLE_7
            TITLE_8
            SECOND_STEP {
              BULLET
              BUTTON_3
            }
            ERROR {
              TITLE_10
            }
          }
        }
      }
    }
  `);

  const textByAction = (action: string | undefined) => {
    const actions: Record<string, string> = {
      ['finished']: t(data).RETURN_WHATSAPP,
      ['redirect']: t(data).LIVENESS.SECOND_STEP.BUTTON_3,
    };
    return (action && actions[action]) || t(data).LIVENESS.ERROR.TITLE_10;
  };

  return (
    <section className="content">
      <h1 className="text-center text-4xl mb-5 leading-tight text-primary-900 tracking-tighter font-thin">
        {t(data).LIVENESS.TITLE_6} <b className="font-medium">{t(data).LIVENESS.TITLE_7}</b> {t(data).LIVENESS.TITLE_8}
      </h1>
      <img src={getPublicURL(`/theme/assets/images/feedback/generic-success.svg`)} className="mt-10 mx-auto" alt="success" />
      {feedback.name && <p className="text-gray-600 text-center text-sm mt-4">{feedback.name}</p>}
      {feedback.government_identification_type && feedback.government_identification && (
        <p className="text-gray-600 text-center text-sm mt-1">
          {feedback.government_identification_type}: {cuitFormatter(feedback.government_identification)}
        </p>
      )}
      <div className="flex flex-col">
        <Button
          color={'primary'}
          className="mx-auto mt-10 py-3"
          onClick={() => {
            callback();
          }}
        >
          {textByAction(action)}
        </Button>
      </div>
    </section>
  );
};
